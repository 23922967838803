* {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: 'Roboto', sans-serif;
}

.heading {
    font-size: 28px;
}

.subheading {
    font-size: 18px;
    color: #75726d;
}

.disclaimer {
    text-align: center;
    color: #89847e;
}

.home,
.checkin,
.update,
.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button,
.button a {
    background-color: #f1bc45;
    color: #cc0000;
    padding: 10px 12px 10px 12px;
    font-weight: bold;
    font-size: 32px;
    text-decoration: none;
    margin: 10px 0px;
}

.button:hover,
.button a:hover {
    background-color: #cc0000;
    color: #f1bc45;
    cursor: pointer;
}
