.checkin {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.checkin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.input-container {
    margin-bottom: 15px;
    width: 100%;
}

.input-container .ant-input,
.input-container .ant-picker,
.input-container .ant-select {
    width: 100%;
}

.switch-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.switch-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 15px;
}
