.navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #cc0000;
    padding: 20px;
    height: 30px;
    margin-bottom: 20px;
    z-index: 100;
    user-select: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.title {
    color: white;
    font-size: 12px;
}

.hamburger-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: white;
    border-radius: 3px;
    margin: 3px 0;
    transition: 0.3s;
}

.open .bar:nth-child(1) {
    transform: translateY(10px) rotate(-45deg);
}

.open .bar:nth-child(2) {
    opacity: 0;
}

.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
}

.nav-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #cc0000;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    list-style-type: none;
    text-align: center;
}

.open .nav-links {
    transform: scaleY(1);
}

.nav-links li {
    padding: 15px 0;
}

.nav-links li a {
    font-size: 24px;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav-links li a:hover {
    color: #ffcccc;
}

.nav-links li:last-child {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .nav-links {
        position: static;
        transform: none;
        flex-direction: row;
        background-color: transparent;
        align-items: center;
        justify-content: right;
    }

    .title {
        width: 60%;
    }

    .nav-links li {
        padding: 0 15px;
    }

    .nav-links li:last-child {
        margin-bottom: 0px;
    }

    .hamburger-icon {
        display: none; /* Hide hamburger icon on larger screens */
    }
}
